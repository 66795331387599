import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"

import animx_image from "assets/images/graph_editor.png"
import wgen_image from "assets/images/hair_1.png"
import rig_image from "assets/images/rig_proto.png"
import shading_image from "assets/images/shading.png"
import wgen_variants_image from "assets/images/hair_variants.png"
import usd_editing_image from "assets/images/usd_editing.png"

import LayoutDefault from "layouts/LayoutDefault"
import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"
import ModalVideo from "components/elements/ModalVideo"
import ButtonGroup from "components/elements/ButtonGroup"
import RequestAccessButton from "components/layout/RequestAccessButton"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
       for Digital <span className="text-color-primary">Avatars</span>
      </>
    ],
    paragraph:
      <> Your <span className="text-color-primary">cost-effective</span> platform for creating 3D digital avatars </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content"
              style={{
                paddingBottom: 32,
              }}
            />
            <div className="center-content">
              <ButtonGroup>
                <RequestAccessButton name={"Request Access"} />
              </ButtonGroup>
            </div>
            <div className={splitClasses}>
            <SolutionFeaturesTiles/>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">USD</h3>
                  <p className="m-0">
                    Wizart DCC Platform is a direct <a href="https://graphics.pixar.com/usd">Pixar's Universal Scene Description (USD) </a> editor that gives you a future-proof data format for all your avatar data compatible with many third-party applications. If this is not enough, you can also export your data to <a href="https://www.alembic.io/">Alembic</a> or <a href="https://developer.apple.com/documentation/arkit/usdz_schemas_for_ar">USDZ</a>. We are also working on adding <a href="https://www.autodesk.com/products/fbx/overview">FBX</a> and <a href="https://www.khronos.org/gltf/">GLTF</a> support.

                    Using <b>Hydra</b> as a native viewport and <b>Hydra Render Delegates</b> ecosystem, you can preview your avatars using the majority of renderers on the market.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="-UeXXgjN7yY"
                    width={528}
                    height={396}
                    thumbnailSrc = {usd_editing_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">Avatar Generator Platform</h3>
                  <p className="m-0">
                    In case you need design you own digital avatar generator for your market, USD <b>Variants</b> and <b>VariantSet</b> features will help you define all combinatorial body, head, clothing accessories
                    without programming. If you want more we have extensive <b>Python API</b> and <b>C++  API</b>, and its possible to use app in container on servers, in order to deliver avatar server processing for your app.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="GVm_RDC9Mj0"
                    width={528}
                    height={396}
                    thumbnailSrc={wgen_variants_image}
                  />
                </div>
              </div>
              
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">Shading</h3>
                  <p className="m-0">
                    Node Editor allows you to modify any USDShade shading networks for any target renderer. This way you can interactively author materials for you digital avatar assets.
                    To make it more future-proof, we also working on adding support for <a href="https://www.materialx.org/">MaterialX</a>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="qDqClwhsQU4"
                    width={528}
                    height={396}
                    thumbnailSrc={shading_image}
                  />
                </div>
              </div>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div> */}
                  <h3 className="mt-0 mb-12">AnimX</h3>
                  <p className="m-0">
                    You can author digital avatars animation using <a href="https://github.com/Autodesk/animx">AnimX</a> compatible animation curves animation engine. 
                    Using Graph Editor, so you can edit animation right inside the app.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="Kh_Epd-UPDU"
                    width={528}
                    height={396}
                    thumbnailSrc = {animx_image}
                  />
                </div>
              </div>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Hair System
                  </div>
                  <h3 className="mt-0 mb-12">WGEN</h3>
                  <p className="m-0">
                    Wgen is a node-based hair system, with support of industry standard{" "}
                    <a href="https://github.com/AcademySoftwareFoundation/OpenShadingLanguage">
                      OSL
                    </a>{" "}
                    networks to add noise to curve shape or to modify hair color using textures.
                    Using curves sculpting toolset, so you can shape any haircut you would like in artist-friendly way.
                    Currently we working on adding feature that allow you to export hair <a href="https://www.unrealengine.com/">
                      Unreal Engine
                    </a> compatible alembic data.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="k6FaTvMmPYM"
                    width={528}
                    height={396}
                    thumbnailSrc = {wgen_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                   In development
                  </div>
                  <h3 className="mt-0 mb-12">Animation/Rigging system</h3>
                  <p className="m-0">
                   Using our experience as animation studio, we developing our own animation and rigging toolset, using modern tech and workflows, but
                   with industry-standard UI, so it would be easier for your artist to learn.
                   It would help to animate your avatar in place, using standard animation workflows, like using <b>Control Rigs</b>, without using any third-party application.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="tOPIT2x7_f4"
                    width={528}
                    height={396}
                    thumbnailSrc={rig_image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index